import BlogLine from "../../../components/BlogLine";
import GeneralTitle from "../../../components/GeneralTitle";
import PageStructure from "../../../components/PageStructure";
import VideoLine from "../../../components/VideoLine";
import AcademicPartners from "../../../components/page-components/AcademicPartners";

const Disclosure = () => {
  const data = [
    [
      {
        title:
          "Name of the school with address:(strictly as per Affiliation sanction letter or as permitted by the Board) with pin code no.",
        value:
          "DOLPHIN INTERNATIONAL SCHOOL, TENGPUNA, PULWAMA JAMMU & KASHMIR-192301",
      },
      {
        title: "Email",
        value: "info@dolphinschool.edu.in & dolphin.in.schl@gmail.com ",
      },
      {
        title: "Phone no.",
        value: "9906944622/9797722202",
      },

      // {
      //   title: "COMPLETE ADDRESS WITH PINCODE",
      //   value: "TENGPUNA, PULWAMA JAMMU & KASHMIR-192301",
      // },
    ],
    {
      title: "Year of the establishment of school",
      value: "01-01-2008",
    },
    [
      {
        title:
          "Whether NOC from state/UT or recommendation of Embassy of India obtained?",
        value: "Yes from CBSE",
      },
      {
        title: "NOC No.",
        value: "JKSED/CBSE/29360",
      },
      {
        title: "NOC issuing date",
        value: "19-Sep-2023",
      },
    ],

    {
      title: "Is the school recognized, if yes by which authority",
      value: "Govt. of J&K School Education Department",
      link: "/files/recognisation_dolphin.pdf",
    },
    [
      {
        title: "Status of affiliation: Permanent/Regular/Provisional",
        value: "Till 2025",
        link: "/files/affiliation.pdf",
      },
      { title: "(I)Affiliation No. (J&K Board)", value: "AFFI000135" },
      // { title: "(ii) Affiliation with the CBSE since", value: "2023" },
      { title: "(iii) Affiliation up to", value: "Mar 2025" },
    ],

    [
      {
        title:
          "Name of Trust/Society/Company Registered under Section 25 of the company Act, 1956.",
        value: "Kashmir Environmental and Educational Foundation",
      },
      {
        title: "Period upto which Registration of Trust/Society is valid",
        value: "PERPETUAL",
      },
    ],
    {
      title: "Mandatory Public Disclosure",
      value: "",
      link: "/files/disclosure.pdf",
    },
  ];

  const Basic = [
    {
      id: 1,
      name: "Mohammad Farooq Fazil",
      designation: "Hafizullah Fazil",
      contact: "P.G with MBA",
      email: "Educationist",
      fax: "Chairman",
    },
    {
      id: 2,
      name: "Abid Hameed Sheikh",
      designation: "AB Hameed Sheikh",
      contact: "M.Sc",
      email: "Business",
      fax: "Vice Chairman",
    },
    {
      id: 3,
      name: "Syed Muneer Qadri",
      designation: "Syed Reyaz Qadri",
      contact: "MBA B.Ed",
      email: "Business",
      fax: "Secretary",
    },
    {
      id: 4,
      name: "Dilafroza",
      designation: "Syed Hussam ud Shah",
      contact: "M.A B.Ed",
      email: "Vice Principal",
      fax: "Member",
    },
    {
      id: 5,
      name: "Bilal Ahmad Dar",
      designation: "Gh. Nabi Dar",
      contact: "M.C.A B.Ed",
      email: "Staff Representative",
      fax: "Member",
    },
    {
      id: 6,
      name: "Reyaz Ahmad Wani",
      designation: "Gh. Nabi Wani",
      contact: "LLM",
      email: "Pvt. service",
      fax: "Member",
    },
    {
      id: 7,
      name: "Altaf Hussain",
      designation: "Ab. Majeed Allie",
      contact: "Post Graduat",
      email: "Chartered Accountant",
      fax: "Member(Parent)",
    },
  ];

  const dataThree = [
    {
      id: 1,
      title: "Name of the principal",
      value: "Ms Lopa Shah",
    },
    {
      id: 2,
      title: "Principal’s contact number",
      value: "9797722202",
    },
    {
      id: 3,
      title: "Principal’s email id",
      value: "principal@dolphinschool.edu.in",
    },
    {
      id: 4,
      title: "Principal’s retirement date",
      value: "-",
    },
    {
      id: 5,
      title: "School’s contact number",
      value: "9906944622/9797722202",
    },
    {
      id: 6,
      title: "School’s email id",
      value: "info@dolphinschool.edu.in",
    },
    {
      id: 7,
      title: "School’s website",
      value: "dolphinschool.edu.in",
    },
    {
      id: 8,
      title: "Landmark near school",
      value: "Jamia masjid Sharief",
    },
    {
      id: 9,
      title: "Year of establishment of school",
      value: "01-01-2008",
    },
    {
      id: 10,
      title: "Affiliation validity",
      value: "March 2025",
    },
    {
      id: 11,
      title: "Affiliation status",
      value: "Provisional",
    },
    {
      id: 12,
      title: "Name of the trust/society/company",
      value: "Kashmir Environmental and Educational Foundation",
    },
    {
      id: 13,
      title: "Society registration number",
      value: "3759-s of 2001",
    },
    {
      id: 14,
      title: "Registration date",
      value: "12-10-2001",
    },
    {
      id: 16,
      title: "NOC issuing authority",
      value: "Education Department, Civil Secretariat J&K",
    },
    {
      id: 17,
      title: "NOC issuing date",
      value: "19-09-2023",
    },
    {
      id: 18,
      title: "NO OBJECTION CERTIFICATE (NOC)",
      link: "/files/noc_cbsc.pdf",
    },
    {
      id: 19,
      title: "NON PROPRIETY CHARACTER AFFIDAVIT",
      link: "/files/non_properiety_character_affidavit.pdf",
    },
    {
      id: 20,
      title: "SELF CERTIFICATION PROFORMA",
      link: "/files/self_certification.pdf",
    },
  ];

  const schoolPhotosAndVideos = [
    {
      id: 1,
      title: "GEO-TAGGED PHOTOGRAPH OF SCHOOL BUILDING",
      link: "/files/school_building.jpg",
    },
    {
      id: 2,
      title: "PHOTOGRAPHS OF SCHOOL LIBRARIES",
      link: "/files/school_library.jpeg",
    },
    {
      id: 3,
      title: "PHOTOGRAPH OF SCHOOL PLAYGROUND",
      link: "/files/school_playground.jpeg",
    },
    {
      id: 4,
      title: "PHOTOGRAPH OF SCHOOL LABORATORIES",
      link: "/files/school_library3.jpeg",
    },
    {
      id: 5,
      title: "VIDEO CLIP OF SCHOOL",
      link: "https://app.dolphinschool.edu.in/public/upload/videos/intro.mp4",
    },
  ];

  const dataFour = [
    {
      title: "TOTAL NUMBER OF TEACHERS (ALL CLASSES) ",
      num: "61",
    },
    {
      title: "NUMBER OF PGTs",
      num: "26",
    },
    {
      title: "NUMBER OF TGTs",
      num: "18",
    },
    {
      title: "NUMBER OF PRTs",
      num: "14",
    },
    {
      title: "NUMBER OF PETs",
      num: "3",
    },
    {
      title: "OTHER NON TEACHING STAFF",
      num: "41",
    },
  ];

  const studentDetails = [
    {
      id: 1,
      title: "TOTAL NUMBER OF SECTIONS PER CLASS (LKG TO 12)",
      value: "26 Sections",
    },
    {
      id: 2,
      title: "TOTAL NUMBER OF STUDENTS PER CLASS (LKG TO 12)",
      value: "827 Students",
    },
  ];

  const classWiseDetails = [
    // {
    //   class: "Pre Nursery",
    //   sections: "1",
    //   students: "38",
    // },
    // {
    //   class: "Nursery",
    //   sections: "2",
    //   students: "70",
    // },
    {
      class: "LKG",
      sections: "2",
      students: "81",
    },
    {
      class: "UKG",
      sections: "2",
      students: "76",
    },
    {
      class: "1st",
      sections: "2",
      students: "77",
    },
    {
      class: "2nd",
      sections: "2",
      students: "74",
    },
    {
      class: "3rd",
      sections: "2",
      students: "76",
    },
    {
      class: "4th",
      sections: "2",
      students: "70",
    },
    {
      class: "5th",
      sections: "2",
      students: "57",
    },
    {
      class: "6th",
      sections: "2",
      students: "74",
    },
    {
      class: "7th",
      sections: "2",
      students: "72",
    },
    {
      class: "8th",
      sections: "2",
      students: "49",
    },
    {
      class: "9th",
      sections: "2",
      students: "49",
    },
    {
      class: "10th",
      sections: "2",
      students: "56",
    },
    {
      class: "11th",
      sections: "1",
      students: "2",
    },
    {
      class: "12th",
      sections: "1",
      students: "8",
    },
  ];

  const subjectsOffered = [
    {
      name: "NAME OF ALL SUBJECTS OFFERED FOR CLASS 10th",
      value:
        "S.ST, Science, English, Urdu, Hindi, Kashmiri, Maths, Information Technology, Healthcare and Artificial Intelligence",
    },
    {
      name: "NAME OF ALL SUBJECTS OFFERED FOR CLASS 12th",
      value:
        "General English, Maths, Physics, Chemistry, Botany, Zoology, Physical Education and Computer Science ",
    },
    { name: "School Planner 2024", link: "/files/school_planner.pdf" },
  ];

  const dataSec = [
    {
      id: 1,
      title:
        "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION IF ANY",
      link: "/files/affiliation.pdf",
    },
    {
      id: 2,
      title:
        "COPIES OF SOCIETIES/TRUST/COMPANY RESGISTRATION/RENEWAL CERTIFICATE AS APPLICABLE",
      link: "",
    },
    {
      id: 3,
      title:
        "COPY OF NO OBJECTIONCERTIFICATE(NOC) ISSUED, IF APPLICABLE BY THE STATE GOVT/UT",
      link: "/files/noc.pdf",
    },
    {
      id: 4,
      title:
        "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND ITS RENEWAL IF APPLICABLE",
      link: "/files/RecognitionCertificate_11261496330112023.pdf",
    },
    {
      id: 5,
      title:
        "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
      link: "/files/building_safety.pdf",
    },
    {
      id: 6,
      title:
        "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
      link: "/files/fire-and-emg.pdf",
    },
    {
      id: 7,
      title:
        "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION SELF CERFTIFICATE BY SCHOOL",
      link: "/files/affiliation.pdf",
    },
    {
      id: 8,
      title: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATE",
      link: "/files/HEALTHANDSANITATIONCERTIFICATE.jpg",
    },
  ];

  const infraDetails = [
    { name: "Number of sites of School", value: "One" },
    { name: "Total Area of the School", value: "8 Kanals/4046.8 Sq Mtrs" },
    { name: "NUMBER OF BUILDING BLOCKS OF SCHOOL", value: "2" },
    { name: "NUMBER OF PLAYGROUNDS IN SCHOOL", value: "1 Main + 1" },
    { name: "TOTAL AREA OF PLAYGROUND (IN SQUARE METERS)	", value: "5 kanals+" },
    { name: "OTHER NON TEACHING STAFF", value: "20" },
    {
      name: "NUMBER OF CLASS ROOMS",
      value: "36",
    },
    { name: "NUMBER OF RESTROOMS FOR STAFF (MALE & FEMALE)", value: "	2" },
    {
      name: "NUMBER OF TOILETS (STAFF, GIRLS BOYS, DIFFERENTLY-ABLED)",
      value: "30",
    },
    { name: "NUMBER OF LIBRARIES", value: "1" },
    { name: "NUMBER OF LABORATORIES	", value: "8" },
    { name: "NUMBER OF SMART CLASSES", value: "13" },
    { name: "NUMBER OF COMPUTERS IN COMPUTER LAB", value: "36" },
    { name: "WI-FI FACILITY", value: "Wifi-enabled Campus" },
    { name: "WEB SERVER FACILITY", value: "AWS Cloud" },
    { name: "AVAILABILITY OF BOUNDARY WALL", value: "Yes" },
    { name: "AVAILABILITY OF CLINIC", value: "Yes" },
    { name: "AVAILABILITY OF STRONG ROOM", value: "1" },
    { name: "CLINIC DETAILS (DOCTORS, NURSES, BEDS)", value: "0, 1, 4" },
    {
      name: "SPORTS FACILITY	",
      value: "yes, Indoor and Outdoor Sports Facilities",
    },
    {
      name: "AVAILABILITY OF FIRE EXTINGUISHERS",
      value: "Yes (60)",
      link: "/files/fire-and-emg.pdf",
    },
    {
      name: "AVAILABILITY OF WATER SPRINKLERS",
      value: "Yes (2 + 2 RO Machines)",
    },
    { name: "BUILDING SAFETY CERTIFICATE", link: "/files/building_safety.pdf" },
    {
      name: "TRANSPORT SAFETY CERTIFICATE",
      link: "/files/transport_safety_certificate.pdf",
    },
    {
      name: "AV Room Cum Auditoriums Cinema Type with 100 Seating capacity",
      value: "Working on",
    },
    {
      name: "Safe drinking water and sanitation",
      link: "/files/water_safety.pdf",
    },
    // {
    //   name: "Safe Drinking Water & Sanitary Condition Certificate",
    //   link: "/files/water_safety.pdf",
    // },
  ];

  const locationDetails = [
    {
      name: "NAME OF NEAREST NATIONALIZED BANK AND DISTANCE FROM BANK",
      value: "Jammu and Kashmir Bank – 2 kms.",
    },
    {
      name: "NAME OF NEAREST BUS STATION AND DISTANCE FROM STATION",
      value: "1 kms (Circular Road Pulwama)",
    },
    {
      name: "NAME OF NEAREST RAILWAY STATION AND DISTANCE FROM STATION",
      value: "Awntipora 4 Kms",
    },
    {
      name: "NAME OF NEAREST AIRPORT AND DISTANCE FROM AIRPORT",
      value: "Srinagar International Airport – 25 kms.",
    },
    {
      name: "NAME OF NEAREST HOSPITAL AND DISTANCE FROM HOSPITAL",
      value: "District Hospital 2.5 Kms",
    },
    {
      name: "NAME OF NEAREST POLICE STATION AND DISTANCE FROM POLICE STATION",
      value: "Police station Pulwama-2.5 Kms",
    },
    {
      name: "NAME OF NEAREST METRO STATION AND DISTANCE FROM METRO STATION (IF AVAILABLE)",
      value: "Not Available",
    },
  ];

  const otherVitalDetails = [
    {
      name: "PASS PERCENTAGE OF LAST THREE YEARS FOR CLASS 10",
      value: "100% in 2021, 100% in 2022 and 100% in 2023",
    },
    {
      name: "NAME OF WELLNESS/ACTIVITY TEACHER",
      value: "Miss Afeefa Farooq",
    },
    {
      name: "DETAILS OF GRIEVANCE/COMPLAINT REDRESSAL COMMITTEE",
      value: "Mr Riyaz Ahmad (9906760511)",
    },
    {
      name: "DETAILS OF COMMITTEE AGAINST SEXUAL HARASSMENT",
      value: "Lopa Shah (Principal), Afeefa Farooq, Tabassum Mehraj",
    },
    {
      name: "DETAILS OF EMERGENCY CONTACT PERSON",
      value: "Afeefa Farooq (9906944622)",
    },
    {
      name: "FACULTY SALARY DETAILS",
      link: "/files/faculty_salary_details.pdf",
    },
    {
      name: "Fee structure",
      link: "/files/ffrc.pdf",
    },
    {
      name: "ACADEMIC SESSION DETAILS",
      value: "March - February",
    },
    {
      name: "VACATION PERIOD DETAILS",
      value: "December -  February",
    },
    {
      name: "BEST PRACTICES OF SCHOOL",
      value:
        "We are a child-centric school. Our school has a well-established English Lab, CAL Lab, and a fully equipped library with nearly 3000 books.",
    },
    {
      name: "STAFF STATEMENT OF SCHOOL (TEACHING & NON TEACHING)",
      link: "/files/staff_statement.pdf",
    },
    {
      name: "THE STAFF STATEMENT DOCUMENT",
      link: "/files/staff_statement.pdf",
    },
    {
      name: "AUDITED BALANCE SHEET OF SCHOOL",
      link: "/files/audited_balance_sheet.pdf",
    },
  ];

  const downloadFile = (file) => {
    window.open(file, "__blank");
  };

  const SingleTableItem = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.title}</div>
        <div className="table_desc">
          {item.value}
          {item?.link && (
            <a onClick={() => downloadFile(item.link)}>Download</a>
          )}
        </div>
      </div>
    );
  };

  const SingleTable2Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry teacher_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.name}</div>
        <div className="table_desc">{item.designation}</div>
        <div className="table_second_contact">{item.contact}</div>
        <div className="table_second_email">{item.email}</div>
        <div className="table_second_fax">{item.fax}</div>
      </div>
    );
  };

  const SingleTable3Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry three_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.title}</div>
        {item?.value && <div className="table_desc">{item.value}</div>}
        {item?.link && <a onClick={() => downloadFile(item.link)}>Download</a>}
      </div>
    );
  };

  const SingleTable4Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry four_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.title}</div>
        <div className="table_desc">{item.num}</div>
      </div>
    );
  };
  const SingleTable7Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry four_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.title}</div>
        <div className="table_desc">{item.value}</div>
      </div>
    );
  };

  const SingleTable8Item = ({ item }) => {
    return (
      <div className="bold-text font10 primary-text table_entry eight_table_item">
        <div className="table_title">{item.class}</div>
        <div className="table_desc">{item.sections}</div>
        <div className="table_desc">{item.students}</div>
      </div>
    );
  };

  const SingleTable9Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry nine_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.name}</div>
        {item.value && <div className="table_desc">{item.value}</div>}
        {item.link && <a onClick={() => downloadFile(item.link)}>Download</a>}
      </div>
    );
  };

  const SingleTable10Item = ({ serial, item }) => {
    console.log(serial);
    return (
      <div
        key={serial ?? "no_key"}
        className="bold-text font10 primary-text table_entry ten_table_item"
      >
        {serial !== 1000 ? (
          <div className="table_index">{serial + 1}</div>
        ) : (
          "-"
        )}
        <div className="table_title">{item.name}</div>
        {item.value && <div className="table_desc">{item.value}</div>}
        {item.link && <a onClick={() => downloadFile(item.link)}>Download</a>}
      </div>
    );
  };

  return (
    <div className="general_page community_wrapper">
      <div className="title font16 bold-text secondary-text font22">
        <GeneralTitle title="Mandatory Public Disclosure" />
      </div>
      <PageStructure>
        <div className="left_side">
          <GeneralTitle title={"General Information"} />
          <div className="table_parent">
            {data?.map((item, sNo) => {
              if (Array.isArray(item)) {
                return item.map((childItem, index) => (
                  <SingleTableItem
                    serial={index == 0 ? sNo : 1000}
                    item={childItem}
                  />
                ));
              } else {
                return <SingleTableItem serial={sNo} item={item} />;
              }
            })}
          </div>

          {/* MANAGING COMMITEE SECTION  */}
          <div className="table_second_parent mt40">
            <GeneralTitle
              title={"List of members of school Managing Committee"}
            />
            <div className="table_parent">
              {Basic?.map((item, sNo) => (
                <SingleTable2Item serial={sNo} item={item} />
              ))}
            </div>
            <div
              className="bold-text mb50 link"
              onClick={() =>
                downloadFile("/files/managing_committe_dolphin.pdf")
              }
            >
              {" "}
              Download
            </div>
          </div>

          {/* BASIC DETAILS SECTION  */}
          <div className="table_three">
            <GeneralTitle title={"BASIC DETAILS"} />
            <div className="table_parent">
              {dataThree?.map((item, sNo) => (
                <SingleTable3Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* SCHOOL PHOTOS AND VIDEOS SECTION  */}
          <div className="table_three mt40">
            <GeneralTitle title={"SCHOOL PHOTOS AND VIDEOS"} />
            <div className="table_parent">
              {schoolPhotosAndVideos?.map((item, sNo) => (
                <SingleTable3Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* FACULTY DETAILS SECTION  */}
          <div className="table_four mt40">
            <GeneralTitle title={"FACULTY DETAILS"} />
            <div className="table_parent">
              {dataFour?.map((item, sNo) => (
                <SingleTable4Item serial={sNo} item={item} />
              ))}
            </div>
          </div>
          {/* STUDENT DETAILS SECTION  */}
          <div className="table_seven mt40">
            <GeneralTitle title={"STUDENT DETAILS"} />
            <div className="table_parent">
              {studentDetails?.map((item, sNo) => (
                <SingleTable7Item serial={sNo} item={item} />
              ))}
            </div>
          </div>
          {/* STUDENT DETAILS SECTION CLASS WISE  */}
          <div className="students_detail_table mt40">
            <GeneralTitle title={"CLASS/SECTION-WISE STUDENT DETAILS"} />
            <div className="table_parent">
              <div className="students_detail_table_heading">
                <div>CLASS</div>
                <div>NUMBER OF SECTIONS</div>
                <div>NUMBER OF STUDENTS</div>
              </div>
              {classWiseDetails?.map((item) => (
                <SingleTable8Item item={item} />
              ))}
            </div>
          </div>

          {/* ACADEMIC DETAILS SECTION  */}
          <div className="academic_table mt40">
            <GeneralTitle title={"ACADEMIC DETAILS"} />
            <div className="table_parent">
              {subjectsOffered?.map((item, sNo) => (
                <SingleTable9Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* INFRASTRUCTURE DETAILS SECTION  */}
          <div className="infrastructure_table mt40">
            <GeneralTitle title={"INFRASTRUCTURE DETAILS"} />
            <div className="table_parent">
              {infraDetails?.map((item, sNo) => (
                <SingleTable10Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* LOCATION DETAILS SECTION  */}
          <div className="location_table mt40">
            <GeneralTitle title={"LOCATION DETAILS"} />
            <div className="table_parent">
              {locationDetails?.map((item, sNo) => (
                <SingleTable10Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* OTHER VITAL INFORMATION SECTION  */}
          <div className="other_vital_table mt40">
            <GeneralTitle title={"OTHER VITAL INFORMATION"} />
            <div className="table_parent">
              {otherVitalDetails?.map((item, sNo) => (
                <SingleTable10Item serial={sNo} item={item} />
              ))}
            </div>
          </div>

          {/* <div className="table_parent_sec">
            <GeneralTitle title={"B. DOCUMENTS AND INFORMATION"} />
            <div className="sec_table">
              {dataSec?.map((item) => {
                return (
                  <div className="table_parent_sec_inner">
                    <div className="table_title">{item.title}</div>
                    <div className="table_desc">
                      {item?.link && (
                        <a onClick={() => downloadFile(item.link)}>Download</a>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
        <div className="right_side">
          <BlogLine size={11} />
        </div>
      </PageStructure>
      <VideoLine />
      <AcademicPartners />
    </div>
  );
};

export default Disclosure;
