import { atom, selector } from "recoil";
import prePrimary from "../assets/videos/preprimary.mp4";
import primary from "../assets/videos/primary.mp4";
import middle from "../assets/videos/middle.mp4";

export const itemsState = atom({
  key: "itemsState",
  default: [
    { name: "Tea", price: 60, sku: 0 },
    { name: "Coffee", price: 80, sku: 1 },
  ],
});

export const cartState = atom({
  key: "cartState",
  default: [],
});

export const shippingState = atom({
  key: "shippingState",
  default: [
    { name: "1 day Delivery", price: 500, selected: false },
    { name: "3 day Delivery", price: 200, selected: false },
    { name: "7 day Delivery", price: 100, selected: false },
  ],
});

export const totalState = selector({
  key: "totalsState",
  get: ({ get }) => {
    const productsTotal = get(cartState);
    // const shippingTotal = get(shippingState);

    let subTotal = 0;
    let shipping = 0;
    productsTotal &&
      productsTotal.length > 0 &&
      productsTotal.map((val) => {
        subTotal = subTotal + (val.price * val.quantity || 1);
      });

    shipping = get(shippingState).filter((val) => val.selected);

    return {
      subTotal,
      shipping: shipping && shipping.length > 0 && shipping[0].price,
      total:
        shipping && shipping.length > 0
          ? shipping[0].price + subTotal
          : 0 + subTotal,
    };
  },
});

export const experienceItemsState = atom({
  key: "experienceItemsState",
  default: [
    {
      id: 0,
      name: "FOUNDATION STAGE (PRE-NURSERY to GRADE 2)",
      desc: "At Dolphin, the Foundation Stage nurtures and develops young students as caring and active participants in a lifelong journey of learning.",
      src: prePrimary,
      type: "video",
      link: "/learning/foundation",
    },
    {
      id: 1,
      name: "PREPARATORY STAGE (GRADE 3 to GRADE 5)",
      desc: " The Preparatory Stage at Dolphin extends learning by helping students develop literacy, numeracy, and cognitive abilities.",
      src: primary,
      type: "video",
      link: "/learning/preparatory",
    },
    {
      id: 2,
      name: "MIDDLE STAGE (GRADE 6 to GRADE 8)",
      desc: "  The Middle Stage at Dolphin, empowers students to connect their academic studies to the real world, encourages interdisciplinary learning, fosters a spirit of inquiry, and cultivates essential life skills.",
      // src: "/images/exp_items/diploma.jpg",
      // type: "image",
      src: middle,
      type: "video",
      link: "/learning/middle",
    },
    {
      id: 3,
      name: "SECONDARY STAGE (GRADE 9 to GRADE 12)",
      desc: "  The Secondary Stage at Dolphin represents a crucial phase in a student's educational journey. It builds upon the solid foundation established in the Middle Stage and is dedicated to providing high-quality education with a primary focus on the sciences.",
      src: "/images/exp_items/diploma.jpg",
      type: "image",
      link: "/learning/secondary",
    },
    {
      id: 4,
      name: "INNOVATION IN PRACTICE",
      desc: "Innovation in Practice: Practice-based project which involves learning new skills and students getting their hands dirty in making what they collectively envision",
      src: "/images/exp_items/tech.jpg",
      type: "image",
      link: "/enrichment/technology",
    },
    {
      id: 5,
      name: "BEING THE CHANGE BY DESIGN",
      desc: "Being the Change by design: Making an innovative product/service that responds to a pressing need around students.",
      src: "/images/exp_items/change_by_design.jpg",
      type: "image",
      link: "/enrichment/arts",
    },
    {
      id: 4,
      name: "EXPOSURE IN EXCHANGE",
      desc: "Exposure in Exchange: Children travel to a different city, live with local host families or school hostels and attend school as a regular student, learning in the process what words like co-existence, cultural plurality and empathy really mean.",
      src: "primary.mp4",
      src: "/images/exp_items/exposure_exchange.jpg",
      link: "/enrichment/sports",
    },
    // {
    //   id: 4,
    //   name: "ENRICHMENT",
    //   desc:
    //     "The Sports programme at DOLPHIN gives importance to students’ health and fitness, and develops leadership skills, team work, and personal growth. ",
    //   src: "primary.mp4",
    //   src: "/images/exp_items/beyond_classroom.jpg",
    //   link: "/enrichment"
    // },
    {
      id: 4,
      name: "WELL BEING IN ACTION ",
      desc: "Well-being in action: Personal and peer well-being through sports, martial arts and arts based therapy.",
      src: "primary.mp4",
      src: "/images/exp_items/well_being.jpg",
    },
    {
      id: 4,
      name: "CELEBRATION",
      desc: "Celebration: Celebration of who we are, where we belong and what we do.",
      src: "primary.mp4",
      src: "/images/exp_items/celebration.jpg",
    },
  ],
});

export const academicPartnersState = atom({
  key: "academicPartnersState",
  default: [],
});

export const aboutPageTabsState = atom({
  key: "aboutPageTabsState",
  default: [
    {
      id: 0,
      name: "About Dolphin",
      active: true,
      link: "about-us/about_dolphin",
    },
    { id: 1, name: "Leadership", active: false, link: "about-us/leadership" },
    {
      id: 2,
      name: "Guiding Statements",
      active: false,
      link: "about-us/guiding_statements",
    },
    { id: 3, name: "Community", active: false, link: "about-us/community" },
    {
      id: 4,
      name: "Mandatory Public Disclosure",
      active: false,
      link: "about-us/disclosure",
    },
  ],
});

export const admissionsPageTabsState = atom({
  key: "admissionsPageTabsState",
  default: [
    { id: 0, name: "Enquire", active: true, link: "admissions/enquire" },
    { id: 1, name: "Registration", active: false, link: "admissions/apply" },
  ],
});

export const learningItemsState = atom({
  key: "learningItemsState",
  default: [
    {
      id: 0,
      image: "/images/exp_items/diploma.jpg",
      title: "FOUNDATION STAGE (PRE-NURSERY to GRADE 2)",
      desc: "At Dolphin, the Foundation Stage nurtures and develops young students as caring and active participants in a lifelong journey of learning.",
      active: true,
      link: "learning/foundation",
    },
    {
      id: 1,
      image: "/images/exp_items/boarding.jpg",
      title: "PREPARATORY STAGE (GRADE 3 to GRADE 5)",
      desc: "The Preparatory Stage at Dolphin extends learning by helping students develop literacy, numeracy, and cognitive abilities.",
      active: false,
      link: "learning/preparatory",
    },
    {
      id: 2,
      image: "/images/exp_items/art.jpg",
      title: "MIDDLE STAGE (GRADE 6 to GRADE 8)",
      desc: "The Middle Stage at Dolphin, empowers students to connect their academic studies to the real world, encourages interdisciplinary learning, fosters a spirit of inquiry, and cultivates essential life skills.",
      active: false,
      link: "learning/middle",
    },
    {
      id: 3,
      image: "/images/exp_items/sports.jpg",
      title: "SECONDARY STAGE (GRADE 9 to GRADE 12)",
      desc: "The Secondary Stage at Dolphin represents a crucial phase in a student's educational journey. It builds upon the solid foundation established in the Middle Stage and is dedicated to providing high-quality education with a primary focus on the sciences.",
      active: false,
      link: "learning/secondary",
    },
  ],
});

export const learningPageTabsState = atom({
  key: "learningPageTabsState",
  default: [
    { id: 0, name: "Foundation", active: true, link: "learning/foundation" },
    { id: 1, name: "Preparatory", active: false, link: "learning/preparatory" },
    { id: 2, name: "Middle", active: false, link: "learning/middle" },
    { id: 3, name: "Secondary", active: false, link: "learning/secondary" },
  ],
});

export const objectivesState = atom({
  key: "objectivesState",
  default: [
    {
      id: 0,
      title: "Learning and Teaching",
      desc: "Maximise and enhance every student’s potential by emphasising a personalised learning environment throughout the school based on the latest pedagogical research.",
    },
    {
      id: 1,
      title: "Infrastructure, School Growth and Development",
      desc: "Develop flexible and responsive systems and a culture of adaptability and innovation to establish Dolphin as a school of choice in India.",
    },
    {
      id: 2,
      title: "Well-Being and Child Protection",
      desc: "Create the best physical and emotional environment to ensure the safety and well-being of all members of the community",
    },
    {
      id: 3,
      title: "Governance",
      desc: "Ensure effective leadership which is modeled on the duty of care, learning, trust, and accountability, that best serves the school now and in the future.",
    },
    {
      id: 4,
      title: "Financial Sustainability",
      desc: "Responsible planning and management of resources to be financially stable and self-sustaining.",
    },
    {
      id: 5,
      title: "Identity",
      desc: "Build our reputation and be recognised as a world- class international school in India with a clear and compelling identity.",
    },
  ],
});

export const exploreSectionsState = atom({
  key: "exploreSectionsState",
  default: [
    {
      id: 0,
      image: "/images/exp_items/diploma.jpg",
      title: "Dolphin in Media",
      desc: "Newsroom - Dolphin news published in the media",
      active: true,
      link: "explore-dolphin/media",
    },
    {
      id: 1,
      image: "/images/exp_items/boarding.jpg",
      title: "News and Events",
      desc: "Current happenings at Dolphin",
      active: false,
      link: "explore-dolphin/news",
    },
    {
      id: 2,
      image: "/images/exp_items/art.jpg",
      title: "Publications",
      desc: "Archive of all our Newsletters",
      active: false,
      link: "explore-dolphin/publications",
    },
  ],
});

export const explorePageTabsState = atom({
  key: "explorePageTabsState",
  default: [
    {
      id: 0,
      name: "Dolphin In Media",
      active: true,
      link: "explore-dolphin/media",
    },
    { id: 1, name: "Blogs", active: false, link: "explore-dolphin/blogs" },
    {
      id: 2,
      name: "Publications",
      active: false,
      link: "explore-dolphin/publications",
    },
  ],
});

export const newsItemsState = atom({
  key: "newsItemsState",
  default: null,
  //   [
  //   { id: 0, image: "/images/news/news1.jpg", title: "Dolphin International School Celebrates the Graduating Class of 2023 at a Beautiful Graduation Ceremony", date: "May 23, 2023", pub: "ABP" },
  //   { id: 1, image: "/images/news/news2.jpg", title: "Dolphin International School donated the proceeds raised during the Dolphin Founder’s Day Run 2021 to The Morning Star Ashram", date: "May 20, 2021", pub: "ABP" },
  //   {
  //     id: 2, image: "/images/news/news3.jpg", title: "Connecting The World Through Virtual Activities by John Bertken, Athletic and Activities Director at Dolphin", date: "Aug 19, 2023", pub: "ABP"
  //   },
  //   { id: 3, image: "/images/news/news4.webp", title: "Tech trends shaping the future of post pandemic teaching & learning, an article by Joe Lumsden, Secondary School Principal at Dolphin", date: "May 23, 2023", pub: "ABP" },
  //   {
  //     id: 4, image: "/images/news/news5.webp", title: "Dolphin International School Celebrates Outstanding IB Results", date: "Jun 23, 2023", pub: "ABP"
  //   },
  //   {
  //     id: 5, image: "/images/news/news6.webp", title: "The Dolphin International School Science Bowl, September 2021", date: "July 23, 2023", pub: "ABP"
  //   },
  //   { id: 6, image: "/images/news/news7.webp", title: "Dolphin International School Hosts an Emotional Graduation Ceremony in the Midst of the Pandemic", date: "May 23, 2023", pub: "ABP" },
  // ]
});

export const eventsItemsState = atom({
  key: "eventsItemsState",
  default: [
    {
      id: 0,
      image: "/images/news/news1.jpg",
      title: "Dolphin Celebrates the Graduating Class",
      desc: "Dolphin International School Celebrates the Graduating Class of 2023 at a Beautiful Graduation Ceremony",
    },
    {
      id: 1,
      image: "/images/news/news2.jpg",
      title: "Dolphin International School donated the proceeds",
      desc: "Dolphin International School donated the proceeds raised during the Dolphin Founder’s Day Run 2021 to The Morning Star Ashram",
    },
    {
      id: 2,
      image: "/images/news/news3.jpg",
      title: "Connecting The World Through Virtual Activities",
      desc: "Connecting The World Through Virtual Activities by John Bertken, Athletic and Activities Director at Dolphin",
    },
    {
      id: 3,
      image: "/images/news/news4.webp",
      title: "Tech trends shaping the future of post pandemic",
      desc: "Tech trends shaping the future of post pandemic teaching & learning, an article by Joe Lumsden, Secondary School Principal at Dolphin",
    },
    {
      id: 4,
      image: "/images/news/news5.webp",
      title: "Dolphin Celebrates results",
      desc: "Dolphin International School Celebrates Outstanding IB Results",
    },
    {
      id: 5,
      image: "/images/news/news6.webp",
      title: "The Dolphin Science Bowl",
      desc: "The Dolphin International School Science Bowl, September 2021",
    },
    {
      id: 6,
      image: "/images/news/news7.webp",
      title: "Dolphin International School Hosts an Emotional Graduation",
      desc: "Dolphin International School Hosts an Emotional Graduation Ceremony in the Midst of the Pandemic",
    },
  ],
});

export const pubItemsState = atom({
  key: "pubItemsState",
  default: [
    {
      id: 0,
      image: "/images/pub/pub1.webp",
      title: "June 2023",
      cat: "General",
    },
    {
      id: 1,
      image: "/images/pub/pub2.webp",
      title: "May 2023",
      cat: "General",
    },
    {
      id: 2,
      image: "/images/pub/pub3.jpg",
      title: "April 2023",
      cat: "General",
    },
    {
      id: 3,
      image: "/images/pub/pub4.webp",
      title: "March 2023",
      cat: "General",
    },
    {
      id: 4,
      image: "/images/pub/pub5.webp",
      title: "Feb 2023",
      cat: "General",
    },
    { id: 5, image: "/images/pub/pub6.jpg", title: "Jan 2023", cat: "General" },
    { id: 6, image: "/images/pub/pub7.jpg", title: "Dec 2022", cat: "General" },
  ],
});

export const currentBlogState = atom({
  key: "currentBlogState",
  default: null,
});

export const blogsState = atom({
  key: "blogsState",
  default: [],
});

export const enrichmentPageTabsState = atom({
  key: "enrichmentPageTabsState",
  default: [
    {
      id: 0,
      name: "Being the change by design",
      active: true,
      link: "enrichment/arts",
    },
    {
      id: 1,
      name: "Innovation in Practice",
      active: false,
      link: "enrichment/technology",
    },
    {
      id: 2,
      name: "Exposure in Exchange",
      active: false,
      link: "enrichment/after_school_activities",
    },
    {
      id: 3,
      name: "Well being in Action",
      active: false,
      link: "enrichment/sports",
    },
    {
      id: 4,
      name: "Celebrations",
      active: false,
      link: "enrichment/celebrations",
    },
    // { id: 5, name: "Primary School Trips", active: false, link: "enrichment/primary_school_trips" },
    // { id: 6, name: "Secondary School Trips", active: false, link: "enrichment/secondary_school_trips" },
  ],
});

export const admissionInputState = atom({
  key: "admissionInputState",
  default: {},
});

export const stepsState = atom({
  key: "stepsState",
  default: [
    { label: "Personal Details", active: true },
    { label: "Academic Details", active: false },
    { label: "Decleration", active: false },
  ],
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const menuOpenState = atom({
  key: "menuOpenState",
  default: false,
});
